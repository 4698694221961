import React from "react"
import PropTypes from "prop-types"

import imgHero from "../images/gfx/hero-1.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import "./hero-standard.css"

const Hero = ({ h1, h2, desktopImage, mobileImage }) => (
  <>
    <div className="hero-standard" style={{ backgroundImage: `url(${desktopImage || imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
      <h1>{h1}</h1>
      <h2>{h2}</h2>
    </div>

    <div className="mobile-hero-standard" style={{ backgroundImage: `url(${mobileImage || imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
      <h1>{h1}</h1>
      <h2>{h2}</h2>
    </div>
  </>
)

Hero.propTypes = {
  h1: PropTypes.string.isRequired,
  h2: PropTypes.string.isRequired,
}

export default Hero
