import { Link } from "gatsby"
import React, { Component } from "react"
import imgLogo from "../images/gfx/logo.png"
import imgMenuIcon from "../images/gfx/mobile-menu.png"
import imgMenuCloseIcon from "../images/gfx/mobile-menu-close.png"
import "./nav.css";

class Nav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'sell',
      isNavOpen: false
    };
  }

  handleNavOpen = event => {
    this.setState({ isNavOpen: true })
  }

  handleNavClose = event => {
    this.setState({ isNavOpen: false })
  }

  isActive = (tab) => {
    return this.props.activeTab && this.props.activeTab === tab;
  }

  render() {
    return (
      <>
        <div className="nav">
          <div className="nav__main">
            <Link className="nav__logo" to='/'>
              <img className="nav__logo-img" src={imgLogo} />
            </Link>
            <div className='nav__list'>
              <Link to='/packages/' className={`${this.isActive('packages') ? 'active' : ''} nav__list__link`}>Packages</Link>
              <Link to='/who-we-help/' className={`${this.isActive('who-we-help') ? 'active' : ''} nav__list__link`}>Who we help</Link>
              <Link to='/case-studies/' className={`${this.isActive('case-studies') ? 'active' : ''} nav__list__link`}>Case studies</Link>
              <Link to='/about/' className={`${this.isActive('about') ? 'active' : ''} nav__list__link`}>About</Link>
              <Link to='/blog/' className={`${this.isActive('blog') ? 'active' : ''} nav__list__link`}>Blog</Link>
              <Link to='/contact/' className={`${this.isActive('contact') ? 'active' : ''} nav__list__link`}>Contact</Link>
              <Link to='/help/' className={`${this.isActive('help') ? 'active' : ''} nav__list__link`}>Help</Link>
            </div>
          </div>
          <div className='nav__promo'>
            <div className='nav__promo__contact'>
              Book a demo
              <span className='nav__promo__contact__tel'>0333 344 9868</span>
            </div>
            <div className='nav__promo__cta'>
              <a href="https://passenger.shuttleid.uk/" className="btn">My&nbsp;tickets</a>
            </div>
            <div className='nav__promo__mobile'>
              <Link to='#' onClick={() => { this.setState({ isNavOpen: !this.state.isNavOpen })}}>
                {!this.state.isNavOpen && (
                  <img src={imgMenuIcon} alt="Open menu" />
                )}
                {this.state.isNavOpen && (
                  <img src={imgMenuCloseIcon} alt="Close nenu" />
                )}
              </Link>
            </div>
          </div>
        </div>
        <div className="nav-mobile" style={{ display: this.state.isNavOpen ? 'block' : 'none' }}>
          <Link to='/packages/' className={`${this.isActive('packages') ? 'active' : ''} mobile-nav__list__link`}><span>Packages</span></Link>
          <Link to='/who-we-help/' className={`${this.isActive('who-we-help') ? 'active' : ''} mobile-nav__list__link`}>Who it's for</Link>
          <Link to='/case-studies/' className={`${this.isActive('case-studies') ? 'active' : ''} mobile-nav__list__link`}><span>Case studies</span></Link>
          <Link to='/about/' className={`${this.isActive('about') ? 'active' : ''} mobile-nav__list__link`}><span>About</span></Link>
          <Link to='/blog/' className={`${this.isActive('blog') ? 'active' : ''} mobile-nav__list__link`}>Blog</Link>
          <Link to='/contact/' className={`${this.isActive('contact') ? 'active' : ''} mobile-nav__list__link`}><span>Contact</span></Link>
          <Link to='/help/' className={`${this.isActive('help') ? 'active' : ''} mobile-nav__list__link`}><span>Help</span></Link>
          <Link to="/request-info/" className="btn">Request info</Link>
          <Link to="/book-demo/" className="btn">Book a demo</Link>
        </div>
      </>
    );
  }
};

export default Nav
