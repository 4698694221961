import React from "react"
import { Link } from "gatsby"
import privacyPolicy from "../docs/shuttleid-privacy-policy-updated-july-2023.pdf";
import "./footer.css";
import imgFooterLogo from "../images/gfx/footer-logo.png"

const Footer = () => (
  <footer>
    <div className="footer-parent">
      <div className="footer">
        <div className="mobile-footer-logo">
          <Link to={`/`}><img src={imgFooterLogo} /></Link>
        </div>
        <div className="footer-nav">
          <Link to='/packages/' className='footer-nav__list__link'>Packages</Link>
          <Link to='/case-studies/' className='footer-nav__list__link'>Case studies</Link>
          <Link to='/about/' className='footer-nav__list__link'>About</Link>
          <Link to='/contact/' className='footer-nav__list__link'>Contact</Link>
          <Link to='/help/' className='footer-nav__list__link'>Help</Link>
          <a href="https://passenger.shuttleid.uk/" className='footer-nav__list__link'>My tickets</a>
          <Link to='/staff-shuttle-service/' className='footer-nav__list__link'>Staff shuttle service</Link>
        </div>
        <div className="mobile-footer-btns">
          <Link to="/request-info/" className="btn">Request info</Link>
          <Link to="/book-demo/" className="btn">Book a demo</Link>
        </div>
        <div className="footer-nav-terms">
          <Link to={`/terms/`}>Terms</Link> <span className="footer-nav-terms__divider">|</span> <a href={privacyPolicy}>Privacy policy</a>
        </div>
      </div>
      <div className="footer-btns-bar">
        <div className="footer-btns">
          <Link to="/request-info/" className="btn">Request info</Link>
          <Link to="/book-demo/" className="btn">Book a demo</Link>
        </div>
        <div className="footer-logo">
          <Link to={`/`}><img src={imgFooterLogo} /></Link>
        </div>
      </div>
      <div className="footer-text">
        <p>© {new Date().getFullYear()} ShuttleID. All rights reserved.</p>
        <p>Cevenco Ltd trading as ShuttleID is a limited company registered in England and Wales.</p>
        <p>Registered number: 11901852. Registered office: 233 Main Street, Leeds, LS17 8LA.</p>
      </div>
    </div>
  </footer>
);

export default Footer